import React, { Component } from "react";
import { Link } from "gatsby";

import "./index.css";

export class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailValid: false,
    };
  }

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleEmailUpload = () => {
    console.log(this.state.email);
  };

  onChange = (e) => {
    this.setState({ email: e.target.value }, () => {
      this.setState({ emailValid: this.validateEmail(this.state.email) });
    });
  };

  render() {
    return (
      <footer>
        <div className="links-wrapper">
          <div className="sitemap">
            <h5>Mapa del sitio</h5>
            <ul>
              <li>
                <Link to="/#especialidades">Especialidades</Link>
              </li>
              <li>
                <Link to="/#instalaciones">Instalaciones</Link>
              </li>
              <li>
                <Link to="/contacto">Contacto</Link>
              </li>
            </ul>
          </div>
          <div className="legal">
            <h5>Legal</h5>
            <ul>
              <li>
                <Link to="/">Aviso de privacidad</Link>
              </li>
            </ul>
          </div>
          <div className="newsletter">
            <h5>Newsletter</h5>
            <p>
              Suscríbete a nuestro newsletter y recibe noticias y descuentos
              directamente a tu correo
            </p>
            <div className="email-field">
              <input
                id="email"
                type="email"
                placeholder="john@doe.com"
                value={this.state.email}
                onChange={this.onChange}
              ></input>
              <button
                onClick={this.handleEmailUpload}
                disabled={!this.state.emailValid}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    className="heroicon-ui"
                    d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <hr size="1" />
        <div className="copyright">
          © {new Date().getFullYear()} Especialidades Médicas Tepepan / Todos
          los derechos reservados.{" "}
        </div>
      </footer>
    );
  }
}

export default footer;
