import React from "react"
import PropTypes from "prop-types"

import "normalize.css"
import "./index.css"

import Navbar from "../navbar/index"
import Footer from "../footer/index"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Navbar></Navbar>
        <main>{children}</main>
        <Footer></Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
