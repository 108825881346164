import React, { Component } from "react"
import { Link } from "gatsby"

import Logo from "../../images/logo.png"

import "./index.css"

export class navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrolled: false,
      open: false,
    }
  }

  scrollBehaviour = () => {
    const nav = document.querySelector("nav")
    const main = document.querySelector("main")

    const scrollLimit = document.body.clientWidth < 1201 ? 10 : 80

    if (window.scrollY >= scrollLimit) {
      main.style.paddingTop = nav.offsetHeight + "px"
      this.setState({ scrolled: true })
    } else {
      main.style.paddingTop = 0
      this.setState({ scrolled: false })
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollBehaviour)
  }

  render() {
    return (
      <nav
        className={
          this.state.scrolled
            ? this.state.open
              ? "scrolled open"
              : "scrolled"
            : this.state.open
            ? "open"
            : ""
        }
      >
        <div>
          <div className="brand">
            <Link to="/">
              <img alt="Logo" src={Logo}></img>
            </Link>
          </div>
          <ul className="links">
            <li>
              <Link to="/#especialidades" activeClassName="active">
                Especialidades
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    className="heroicon-ui"
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="/#instalaciones" activeClassName="active">
                Instalaciones
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    className="heroicon-ui"
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="/contacto/" activeClassName="active">
                Contacto
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    className="heroicon-ui"
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  />
                </svg>
              </Link>
            </li>
          </ul>
          <div
            className="menu-icon"
            onClick={e => {
              this.setState({ open: !this.state.open })
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                className="heroicon-ui"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </div>
        </div>
        <ul className="mobile-links">
          <li className="active">
            <Link to="/" activeClassName="active">
              Especialidades
            </Link>
          </li>
          <li>
            <Link to="/" activeClassName="active">
              Instalaciones
            </Link>
          </li>
          <li>
            <Link to="/contacto/" activeClassName="active">
              Contacto
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default navbar
